import React from 'react'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import Section from './components/Section'
import LandingSection from './components/LandingSection'
import { sections } from "./data/sections.js";

class Home extends React.Component {
 render() {
  return (
   <React.Fragment>
    <Navigation />
    <LandingSection />
    {
      sections.map((value, index) => {
       return <Section content={value} dark={index%2 === 0}/>
      })
    }
    <Footer />
   </React.Fragment>
  );
 }
}

export default Home;