import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import { ReactComponent as BlackIcon } from './icon-black.svg'

const StyledBlackIcon = styled(BlackIcon)` 
  width: 100px; 
  height: 100px;
  margin-bottom: 10vh;
`

const StyledContainer = styled(Container)` 
  background-color: white;
  color: black;
  height: 120vh;
  font-family:'Labrix';
  text-align: center;
  padding-top: 20vh;
 `

const LandingSection = (props) => {
  return (
   <StyledContainer maxWidth="sm">
    <StyledBlackIcon />
    <h1>WE DELIVER VALUE THROUGH DATA</h1>
    <h2>Labrix is dedicated to helping businesses and organisations of any size reach their goals through data</h2>
   </StyledContainer>
  );
}


export default LandingSection;