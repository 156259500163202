import React from 'react'
import { ReactComponent as WhiteIcon } from './icon-white.svg'
import styled from 'styled-components'

const StyledWhiteIcon = styled(WhiteIcon)` 
  width: 30px; 
  height: 30px;
`
 
export default function LabrixIcon() {
 return(
  <StyledWhiteIcon/>
 )
}