export const sections = [
  {
    'title': 'WHO WE ARE',
    'paragraphs': [
      'We are a team of professionals with expertise in data, software development and operations management. Our passion is to empower organisations around the world through data and technology. We believe in strong partnerships and we always seek to provide realistic solutions that can make a difference to our clients.'
    ]
  },
  {
    'title': 'WHAT WE DO',
    'paragraphs': [
      'Our core strength is data. From analysing your data employing the latest data science, to setting up and running your data capture and storage infrastructure. Our team of data scientists, engineers and strategists can help you wherever you are in your journey',
      'We help you design, build and maintain great applications. Whether its a simple web app for your internal team, or a full consumer native application for smart phones, our designers and developers can help you provide your users with a valuable application',
      'We find and deliver business value. Our operations and business consultants can help you discover hidden value in your organisation.'
    ]
  },
  {
   'title': 'WHO WE WORK FOR',
   'paragraphs': [
      'We pride ourselves in having a diverse client base. Regardless of the size of your team, or the sector you work in, we can help you.',
      'Our clients include: Fortune 500 companies, SMEs, NGOs and Governments',
      'Our areas of expertise are: Manufacturing, Supply Chain, Public Administration and E-commerce'
    ]
  },
  {
   'title': 'GET IN TOUCH',
   'paragraphs': [
      'To begin our partnership, contact us on contact@labrixdigital.com'
    ]
  }
]