import React from 'react'
import Navigation from './components/Navigation'
import Markdown from 'markdown-to-jsx';

const md = `
# Privacy

## SOME OTHER SUBTITLE

`;

class Privacy extends React.Component {
 render() {
  return (
   <React.Fragment>
    <Navigation />
    <Markdown children={md}/>
   </React.Fragment>
  );
 }
}

export default Privacy;