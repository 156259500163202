import React from 'react'
import Navigation from './components/Navigation'

class ContactPage extends React.Component {
 render() {
  return (
   <React.Fragment>
    <Navigation />
    <h1> Contact us at sales@labrixdigital.com </h1>
   </React.Fragment>
  );
 }
}

export default ContactPage;