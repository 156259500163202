import Home from './Home';
import Privacy from './Privacy';
import NotFoundPage from './NotFoundPage';
import ContactPage from './ContactPage';
import { StylesProvider } from "@material-ui/core/styles";
import GlobalFonts from './fonts/fonts';
import { BrowserRouter, Route, Switch } from "react-router-dom";


function App() {
 return (
  <StylesProvider injectFirst>
   <GlobalFonts />
   <BrowserRouter>
    <Switch>
     <Route path="/" exact component={Home} />
     <Route path="/privacy" component={Privacy} />
     <Route path="/contact" component={ContactPage} />
     <Route component={NotFoundPage} />
    </Switch>
   </BrowserRouter>
  </StylesProvider>  
 );
}

export default App;
