import React from 'react'
import { Grid, Link, Container, TextField, Box, MenuItem} from '@material-ui/core'
import LabrixIcon from './LabrixIcon'
import styled from 'styled-components'

const StyledContainer = styled(Container)` 
 background-color: white;
 color:black;
 font-family:'Labrix';
`
const StyledGridItem = styled(Grid)`
 margin-top:10px;
 margin-bottom:10px;
 text-align:center;
`

class Footer extends React.Component {
 render() {
  return (
   <Grid 
    container
    alignItems="center"
    direction="row"
   >
    <Box component={StyledGridItem} item xs display={{xs:'none', md:'block'}} >
     {'© '}Labrix Digital Consulting{' '}{new Date().getFullYear()}
    </Box>
    <StyledGridItem item xs>
     Contact Us
    </StyledGridItem>
    <StyledGridItem item xs>
     <p>
      Language
     </p>
     <TextField select value="EN" defaultValue="EN">
      <MenuItem value="EN" key="EN">EN</MenuItem>
      <MenuItem value="ES" key="ES">ES</MenuItem>
     </TextField>
    </StyledGridItem>
   </Grid>
  );
 }
}

export default Footer;