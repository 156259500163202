import React from 'react'
import { AppBar, Toolbar, IconButton} from '@material-ui/core'
import LabrixIcon from './LabrixIcon'
import styled from 'styled-components'

const StyledAppBar = styled(AppBar)` 
 background-color: #000
`

class Navigation extends React.Component {
 render() {
  return (
   <StyledAppBar position="sticky">
    <Toolbar>
     <IconButton edge="start" color="inherit" aria-label="home">
      <LabrixIcon />
     </IconButton>
    </Toolbar>
   </StyledAppBar>
  );
 }
}

export default Navigation;