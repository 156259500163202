import { createGlobalStyle } from 'styled-components';

import LabrixWoff from './labrix.woff';

export default createGlobalStyle`
    @font-face {
      font-family: 'Labrix';
      src: local('Labrix'),
      url(${LabrixWoff}) format('woff');
      font-weight: 300;
      font-style: normal;
     }
  `;