import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'


const StyledContainer = styled(Container)` 
  background-color: ${props => props.$dark ? "black" : "white"};
  color: ${props => props.$dark ? "white" : "black"};
  height: 120vh;
  font-family:'Labrix';
  text-align: center;
  padding-top: 20vh;
 `
const StyledDivider = styled.hr `
 border: none;
 width: 80%;
 height: 2px;
 background-color: ${props => props.$dark ? "white" : "black"};
`

const Section = (props) => {
  return (
   <StyledContainer $dark={props.dark} maxWidth="xl">
    <h1>{props.content.title}</h1>
    <StyledDivider $dark={props.dark}/>
    {
     props.content.paragraphs.map((value, index) => {
      return <h2>{value}</h2>
     })
    }
   </StyledContainer>
  );
}


export default Section;